import React, {Suspense , useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import  Header  from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import ProjectChurch from './pages/projects/ProjectChurch';
import ProjectCastle from './pages/projects/ProjectCastle';
import Contacts from './pages/contacts/Contacts';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from 'react-ga';
import './App.scss';


function App() {

  useEffect(() => {
    ReactGA.initialize('G-L4170RCP0G');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])



  return (
    <div>
      <Suspense fallback={null}>
        <Router>
          <ScrollToTop/>
            <Header />
              <Routes>
                <Route exact path='/' element={<Home/>} />
                <Route exact path='/about' element={<About/>} />
                <Route exact path='/project/project-church' element={<ProjectChurch/>} />
                <Route exact path='/project/project-castle' element={<ProjectCastle/>} />
                <Route exact path='/contacts' element={<Contacts/>} />
              </Routes>
            <Footer/>

        </Router>
      </Suspense>
    </div>
  );
}

export default App;
